import * as React from "react";
import Redirect from "../../components/redirect";
//--------------------------------------------------

  const url = "https://ap-southeast-1.console.aws.amazon.com/console/home?region=ap-southeast-1";

//--------------------------------------------------
// common config below this line, no need to change
const IndexPage = () => (
  <Redirect goto_url={url} />
);

export default IndexPage;
